import React from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import "@styles/Privacidad.css";
import SmallBanner from "@components/common/SmallBanner";

export default function Index() {
    return (
        <>
            <Layout>
                <Seo title="Política de Privacidad" />
                <SmallBanner title="Política de Privacidad" filename="bgs/bgPrivacy" />
                <div className="container lg:px-0 py-20">
                    <div className="mb-10 privacidad">
                        <Title className="text-left">
                            ¿Quién es el responsable del tratamiento de sus datos?
                        </Title>
                        <Text className="text-justify">
                            <ul className="ml-5 list-decimal">
                                <li>
                                    <strong>Identidad:</strong> iOpos, SL
                                </li>
                                <li>
                                    <strong>NIF:</strong> ESB09771155
                                </li>
                                <li>
                                    <strong>Dirección postal:</strong> Plaza González Isla, número
                                    10. 43895 L'Ampolla (Tarragona)
                                </li>
                                <li>
                                    <strong>Teléfono:</strong>{" "}
                                    <a className="link" href="tel:34644507478">
                                        +34 644 507 478
                                    </a>
                                </li>
                                <li>
                                    <strong>Correo electrónico:</strong>{" "}
                                    <a href="mailto:info@iopos.academy" className="link">
                                        info@iopos.academy
                                    </a>
                                </li>
                                <li>
                                    <strong>Publicador o publisher de aplicaciones:</strong>{" "}
                                    <a
                                        className="link"
                                        href="https://moybu.com"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        MOYBU, SCP
                                    </a>
                                </li>
                                <li>
                                    <strong>Aplicaciones Android:</strong>
                                    <ul className="list-disc list ml-5">
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.ipol&hl=es_419&gl=US"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                iPol: Opos Policía Local
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.imossos&hl=es_419&gl=US"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                iMossos - Directo al ISPC
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.igc&hl=es_419&gl=US"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                iGC - Oposiciones Guardia Civil
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.ibcn&hl=es_419&gl=US"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                iBarcelona - ¿Me conoces?
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.icnp&hl=es_419&gl=US"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                iCNP - Opos Policía Nacional
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.icorreos&hl=es_419&gl=US"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                iCorreos - Oposiciones Correos
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.moybu.apps.igub2&hl=es_419&gl=US"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                iGuB - Directo al ISPC
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.isanitarios&hl=es"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                iSanitarios - ¡Tu futuro en Sanidad empieza aquí!
                                            </a>
                                        </li>

                                        <li>
                                            <a
                                                href="https://play.google.com/store/apps/details?id=com.moybu.apps.iopos.ipoliciamadrid&hl=es"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="link"
                                            >
                                                iPolicíaMadrid - ¡Tu plaza más cerca!
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">
                            ¿Con qué finalidad tratamos sus datos personales?
                        </Title>
                        <Text className="text-justify">
                            En iOpos, SL, propietario del sitio web, tratamos sus datos personales
                            con el fin de prestar los servicios solicitados, realizar la gestión
                            administrativa, contable y fiscal relacionada, atender sus consultas,
                            así como, en caso de aceptarlo, el envío de comunicaciones comerciales.
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">¿Cuántio tiempo conservamos sus datos?</Title>
                        <Text className="text-justify">
                            Los datos se conservarán mientras usted no nos solicite su supresión y
                            en cualquier caso durante los años necesarios para cumplir con las
                            obligaciones legales.
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">
                            ¿Cuál es la legitimación para el tratamiento de sus datos?
                        </Title>
                        <Text className="text-justify">
                            Las bases legales que legitiman el tratamiento de sus datos por parte
                            nuestra son:
                            <ul className="ml-5 list-decimal mt-5">
                                <li>
                                    Ejecución de un contrato o demanda de servicios: Prestación de
                                    los servicios solicitados
                                </li>
                                <li>
                                    Consentimiento del interesado: Envío de comunicaciones
                                    comerciales y atender sus consultas.
                                </li>
                            </ul>
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">
                            ¿A qué destinatarios se comunicarán su datos?
                        </Title>
                        <Text className="text-justify">
                            Personas o entidades directamente relacionadas con el responsable
                            (asesoría fiscal y contable, bancos, proveedores de servicios con el
                            debido contrato de prestación que prescribe el artículo 28 RGPDUE), y
                            entidades e instancias con las que exista obligación legal.
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">¿Cómo hemos obtenido su datos?</Title>
                        <Text className="text-justify">
                            Cualquier persona tiene derecho a obtener confirmación sobre si a iOpos,
                            SL estamos tratando datos personales que les conciernan, o no.
                            <br />
                            <br />
                            Los interesados ​​tienen derecho a acceder a sus datos personales, así
                            como a solicitar la rectificación de los datos inexactos o, en su caso,
                            solicitar su supresión cuando, entre otros motivos, los datos ya no sean
                            necesarios para los fines que fueron recogidos.
                            <br />
                            <br />
                            En determinadas circunstancias, los interesados ​​podrán solicitar la
                            limitación del tratamiento de sus datos, en este caso únicamente las
                            conservaremos para el ejercicio o la defensa de reclamaciones.
                            <br />
                            <br />
                            En determinadas circunstancias y por motivos relacionados con su
                            situación particular, los interesados ​​podrán oponerse al tratamiento
                            de sus datos. En este caso, iOpos, SL dejará de tratar los datos, salvo
                            por motivos legítimos imperiosos, o el ejercicio o la defensa de
                            posibles reclamaciones.
                            <br />
                            <br />
                            Podrá ejercitar materialmente sus derechos de la siguiente forma: Tiene
                            derecho a acceder, rectificar y suprimir los datos, así como la
                            portabilidad de sus datos, que puede ejercer en la dirección del
                            propietario de la web.
                            <br />
                            <br />
                            Si ha otorgado su consentimiento para alguna finalidad concreta, tiene
                            derecho a retirar el consentimiento otorgado a cualquier momento, sin
                            que ello afecte a la licitud del tratamiento basado en el consentimiento
                            previo a su retirada.
                            <br />
                            <br />
                            En caso de que sienta vulnerados sus derechos lo concerniente a la
                            protección de sus datos personales, especialmente cuando no haya
                            obtenido satisfacción en el ejercicio de sus derechos, puede presentar
                            una reclamación ante la Autoridad de Control en materia de Protección de
                            Datos competente a través de su sitio web: www.agpd.es.
                        </Text>
                    </div>

                    <div className="mb-10 privacidad">
                        <Title className="text-left">Categorías de datos</Title>
                        <Text className="text-justify">
                            Datos identificativos:
                            <ul className="ml-5 list-disc mt-5 mb-5">
                                <li>Nombre y apellidos</li>
                                <li>Dirección postal</li>
                                <li>DNI, NIF o CIF</li>
                                <li>Teléfono/s</li>
                                <li>Dirección de correo electrónico.</li>
                                <li>Firma o firma electrónica</li>
                            </ul>
                            Datos bancarios:
                            <ul className="ml-5 list-disc mt-5 mb-5">
                                <li>
                                    Todas las necesarias para la gestión y / o domiciliación de
                                    cobros y pagos, como el número de cuenta bancaria, medios de
                                    pago electrónicos y / o virtuales y otros medios de pago.
                                </li>
                            </ul>
                            Categorías de datos especiales:
                            <ul className="ml-5 list-disc mt-5 mb-5">
                                <li>
                                    No se tratan categorías especiales de datos personales (es
                                    decir, aquellos datos que revelen el origen étnico o racial, las
                                    opiniones políticas, las convicciones religiosas o filosóficas,
                                    o la afiliación sindical, datos genéticos, datos biométricos
                                    dirigidas a identificar de manera unívoca a una persona física,
                                    datos relativos a la salud o datos relativos a la vida sexual o
                                    la orientación sexual de una persona física).
                                </li>
                            </ul>
                            Otros tipos de datos:
                            <ul className="ml-5 list-disc mt-5">
                                <li>No se tratan otros tipos de datos</li>
                                <li>No se tratan datos especialmente protegidos.</li>
                            </ul>
                        </Text>
                    </div>
                </div>
            </Layout>
        </>
    );
}
